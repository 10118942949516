import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppStateService } from '../app-state.service';
import {
  FlyoutComponent,
  FlyoutNavigationItem,
  HeaderComponent,
  Lab,
  Link,
  UrlUtil,
  User,
  WorkQueue,
  Workspace,
  LocaleService,
} from '@lims-common-ux/lux';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { AccessionHeaderValueService } from './accession-header-value.service';
import { Observable, filter } from 'rxjs';

@Component({
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
  providers: [AccessionHeaderValueService],
})
export class LabComponent implements OnInit, AfterViewInit {
  currentLab: Lab;
  labs: Lab[];
  user: User;
  accessionSearchLink: Link;

  @ViewChild('flyout', { static: false })
  flyout: FlyoutComponent;

  @ViewChild('header', { static: false })
  header: HeaderComponent;

  @ViewChild('helpModalContainer', { static: true })
  helpModalContainer: HelpModalComponent;

  navItems: FlyoutNavigationItem[];
  currentWorkspace: Workspace;

  get accessionHeader(): Observable<Accession> {
    return this.accessionHeaderValue.value$;
  }

  get env() {
    return this.appStateService.env;
  }

  get advancedAcessionSearchLink(): Link {
    return this.appStateService.advancedAccessionSearchLink;
  }

  constructor(
    private appStateService: AppStateService,
    private route: ActivatedRoute,
    private router: Router,
    private accessionHeaderValue: AccessionHeaderValueService,
    private localeService: LocaleService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      // Here because child route data changes don't fire this routes data changes Observable, and we want to catch
      // all workspace changes here to keep this up to date.
      if (this.route.snapshot.children?.length > 0) {
        this.currentWorkspace = this.route.snapshot.children[0].data.workspace;
      } else {
        this.currentWorkspace = null;
      }
      // first time in this will null as nav ends before view is fully initialized
      if (this.header && event instanceof NavigationEnd) {
        this.focusIfLabRoute(event.url);
      }
    });

    this.route.data.subscribe((data) => {
      this.currentLab = data.lab?.lab;
      this.navItems = this.appStateService.workspaces.map((workspace: Workspace) => {
        const queue = this.appStateService.workQueues.find((q: WorkQueue) => q.workspaceId === workspace.id);
        return {
          name: workspace.name,
          id: workspace.id,
          buttonLink: queue?._links?.first
            ? ({
                // relies on the different contexts having the same structure for getting into a queue.
                href: workspace._links.workspaceView.href + '/queue',
              } as Link)
            : undefined,
        } as FlyoutNavigationItem;
      });
    });
  }

  ngOnInit() {
    this.labs = this.appStateService.labs;
    this.user = this.appStateService.user;
    this.accessionSearchLink = this.appStateService.accessionSearchLink;
  }

  private focusIfLabRoute(currentRoute: string) {
    if (currentRoute.endsWith(`/${this.appStateService?.lab?.id}`)) {
      this.header.accessionNumberInput.focusSearchInput();
    }
  }

  // need this as well since the route events register to late when first loading the page. Only works after the page
  // is initially rendered
  ngAfterViewInit() {
    this.focusIfLabRoute(this.route.snapshot.url.join('/'));
  }

  handleUpdateLab(lab: Lab) {
    // clear any accession search values and errors
    this.header.reset();

    if (lab.id === this.appStateService.lab.id) {
      this.header.toggleDropdown();
    }
    this.router.navigate(['/', 'lims-results', lab.id]);

    // Close help modal on lsb change
    this.helpModalContainer.close();
  }

  changeWorkspace(navItem: FlyoutNavigationItem) {
    this.flyout.closeSidebar();
    const workspace = this.workspaceForNavItem(navItem);
    if (workspace.workspaceType === 'CHEMISTRY') {
      const route = UrlUtil.getFragment(workspace._links.workspaceView.href);
      this.router.navigateByUrl(route);
    } else {
      window.location.href = workspace._links.workspaceView.href;
    }
  }

  private workspaceForNavItem(navItem: FlyoutNavigationItem) {
    return this.appStateService.workspaces.find((it) => {
      return it.id === navItem.id;
    });
  }

  startQueue(buttonEvent: FlyoutNavigationItem) {
    this.flyout?.closeSidebar();
    this.triggerRedirect(buttonEvent.buttonLink.href + '?locale=' + this.localeService.selectedLocale);
  }

  triggerRedirect(href: string) {
    window.location.href = href;
  }

  helpModalOpen() {
    this.helpModalContainer.open();
  }
}
