<div *ngIf="currentWorkspace" id="workspace-top">
  <lux-workspace-links [accession]="headerAccession" [currentWorkspace]="currentWorkspace"> </lux-workspace-links>

  <div luxLayout="row" luxLayoutAlign="space-between center" luxLayoutGap="13px">
    <!-- WORKSPACE NAME -->
    <div class="workspace-name" id="selected-workspace" data-cy="selected-workspace">{{ currentWorkspace.name }}</div>
    <!-- /WORKSPACE NAME -->

    <!-- WORKSPACE ACTION BUTTONS -->
    <div luxLayout="row" id="workspace-action-buttons" luxLayoutAlign="end center" luxLayoutGap="20px">
      <button
        id="acceptAll"
        data-cy="accept-all-btn"
        *ngIf="showAcceptAll"
        (click)="acceptAllResults()"
        [disabled]="disableAcceptAll"
        title="{{ 'LABELS.ACCEPT_ALL' | translate }}"
        class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>{{ 'LABELS.ACCEPT_ALL' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#checkmark"></use>
        </svg>
      </button>
    </div>
    <!-- /WORKSPACE ACTION BUTTONS -->
  </div>

  <lux-samples [samples]="workspaceAccession?.samples"></lux-samples>
</div>
