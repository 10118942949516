<div luxLayout="column" luxFill class="spot-typography__text--body">
  <app-global-error-handler (refresh)="handleAccessionRefresh()" [loggedin]="loggedIn" luxLayout="row">
  </app-global-error-handler>

  <div luxFlex>
    <router-outlet></router-outlet>
  </div>

  <lux-modal-container-outlet> </lux-modal-container-outlet>
</div>
