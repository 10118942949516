import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabComponent } from './lab.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { LabResolver } from './lab.resolver';
import { TranslateModule } from '@ngx-translate/core';
import {
  FlyoutModule,
  HeaderModule,
  KeyboardHelpCategoryModule,
  LuxLayoutModule,
  ModalContainerModule,
} from '@lims-common-ux/lux';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LabComponent, HelpModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ModalContainerModule,
    KeyboardHelpCategoryModule,
    HeaderModule,
    FlyoutModule,
    RouterModule,
    LuxLayoutModule,
  ],
  providers: [LabResolver],
})
export class LabModule {}
