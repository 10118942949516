import { Workspace } from '@lims-common-ux/lux';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { AppStateService } from '../../app-state.service';
import { HttpClient } from '@angular/common/http';

export interface EmptyQueueResolverData {
  empty: boolean;
  workspace: Workspace;
}

@Injectable({ providedIn: 'root' })
export class EmptyQueueResolver {
  constructor(private appStateService: AppStateService, private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmptyQueueResolverData> {
    return of(route.parent.data).pipe(
      switchMap((workspaceResolverData) => {
        const workQueue = this.appStateService.workQueues.find(
          (wq) => wq.workspaceId === workspaceResolverData.workspace.id
        );

        return this.http.get(workQueue._links.first.href, { observe: 'response' }).pipe(
          switchMap((responseFromQueueFirstReq) => {
            if (responseFromQueueFirstReq.body) {
              return of({
                empty: false,
                workspace: workspaceResolverData.workspace,
              });
            } else {
              return of({
                empty: true,
                workspace: workspaceResolverData.workspace,
              });
            }
          })
        );
      })
    );
  }
}
